/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

// font
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
// 1st: SCSS variable overrides
$global-prefix : '';


// 2nd: build import
@import '../../../../../../otherSrc/client/we/scss/build';

// our custom component // * NOTE: we can not include partial SCSS files inside the client forlder, atm.

// 3rd: component hooks


@mixin hook-button {
    &--facebook {
        @include _button-color(var(--c-facebook), var(--c-bg), var(--c-bg), var(--c-facebook), true);
        border: 2px solid var(--c-facebook);
    }
}

@mixin hook-modal {
    &--lead {
        .modal__inner {
            background-color: var(--c-lead);
            color: var(--c-bg);
        }
    }
}

// 4th: component import
@import '../../../../../../otherSrc/client/we/scss/components';



// 5th: CSS variables declaration/override
:root {

}

body{
    --base-margin    : calc(100vw / 1540);
    --base-font-size : calc(100vw / 1540);

    @include whenSmallerThan(l) {
        --base-margin: 1px;
        --base-font-size: 1px;
    }

    *{
        --base-margin    : calc(100vw / 1540);
        --base-font-size : calc(100vw / 1540);
        @include whenSmallerThan(l) {
            --base-margin: 1px;
            --base-font-size: 1px;
        }

        --font-min                : calc(10 * var(--base-font-size));
        --font-xs                 : calc(12 * var(--base-font-size));
        --font-s                  : calc(14 * var(--base-font-size));
        --font-m                  : calc(16 * var(--base-font-size));
        --font-l                  : calc(18 * var(--base-font-size));
        --font-xl                 : calc(20 * var(--base-font-size));
        --font-2xl                : calc(25 * var(--base-font-size));
        --font-3xl                : calc(32 * var(--base-font-size));
        --font-max                : calc(38 * var(--base-font-size));

        // margins
        --margin-min              : calc(1 * var(--base-margin));
        --margin-xs               : calc(2 * var(--base-margin));
        --margin-s                : calc(8 * var(--base-margin));
        --margin-m                : calc(12 * var(--base-margin));
        --margin                  : var(--margin-m);
        --margin-l                : calc(18 * var(--base-margin));
        --margin-xl               : calc(32 * var(--base-margin));
        --margin-2xl              : calc(45 * var(--base-margin));
        --margin-max              : calc(60 * var(--base-margin));

        --container-gutter        : calc(20 * var(--base-margin));

        --container-xs-width-seed : calc(400 * var(--base-margin));
        --container-s-width-seed  : calc(800 * var(--base-margin));
        --container-m-width-seed  : calc(1124 * var(--base-margin));
        --container-l-width-seed  : calc(1300 * var(--base-margin));
        --container-xs            : calc(
                var(--container-xs-width-seed) + (2 * var(--container-gutter))
        );
        --container-s             : calc(
                var(--container-s-width-seed) + (2 * var(--container-gutter))
        );
        --container-m             : calc(
                var(--container-m-width-seed) + (2 * var(--container-gutter))
        );
        --container               : var(--container-m);
        --container-l             : calc(
                var(--container-l-width-seed) + (2 * var(--container-gutter))
        );

        --border-radius-none      : 0;
        --border-radius-sharp     : calc(3 * var(--base-margin));
        --border-radius-s         : var(--border-radius-sharp);
        --border-radius           : calc(20 * var(--base-margin));
        --border-radius-m         : var(--border-radius);
        --border-radius-l         : calc(35 * var(--base-margin));
        --border-radius-round     : 50%;

    }
}


// 6th: other custom CSS
*,
*::before,
*::after {
    box-sizing: border-box;

}

// 6th: custom css
html, body  {
    background: var(--c-bg);

    &[lang=he] * {
        font-family: 'Varela Round', 'Assistant', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    }

}
